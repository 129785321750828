import React, { FC, ReactChild, ReactNode, useContext } from 'react'

import styled from 'styled-components'

import { useShallowEqualSelector } from 'common/hooks/useShallowEqualSelector'
import { VoidHandler } from 'common/types'
import { AdBlockBanner } from 'components/banner/AdblockBanner/AdBlockBanner'
import { BannerPopUpRulesCookie } from 'components/banner/BannerPopUpRulesCookie'
import { PhotoLineLoadable } from 'components/block/PhotoLine/PhotoLineLoadable'
import { LayoutContent } from 'components/layout/MambaLayout/LayoutContent'
import {
  photoLineHeight,
  photoLineMargin,
} from 'components/layout/MambaLayout/MambaLayout.constants'
import {
  InternalWidthLimit,
  WidthLimit,
} from 'components/layout/MambaLayout/WidthLimit'
import { ModalWrapper } from 'components/layout/ModalLayout/ModalWrapper'
import { AppHeader } from 'components/presentational/Navigation/AppHeader'

import { LayoutContext } from './MambaLayoutIndex.context'

const AdblockBannerRenderer = () => (
  <div>
    <InternalWidthLimit>
      <AdBlockBanner />
    </InternalWidthLimit>
  </div>
)

export interface InternalContentProps {
  modal: ReactChild
  children: ReactNode
  onClick: VoidHandler
}

export const InternalContent: FC<InternalContentProps> = ({
  children,
  modal,
  onClick,
}) => {
  const { web, authorized } = useShallowEqualSelector(
    ({ systemReducer: { web }, authorizationReducer: { authorized } }) => ({
      web,
      authorized,
    })
  )

  const { photolineVisible } = useContext(LayoutContext)

  return (
    <>
      <ModalWrapper onClick={onClick} data-name="internal-content">
        <AppHeader />
        {authorized && photolineVisible && (
          <PhotoLineWrapper>
            <InternalWidthLimit>
              <PhotoLineLoadable />
            </InternalWidthLimit>
          </PhotoLineWrapper>
        )}
        {web && <AdblockBannerRenderer />}
        <WidthLimit>
          <LayoutContent>{children}</LayoutContent>
        </WidthLimit>
      </ModalWrapper>
      <BannerPopUpRulesCookie />
      {modal}
    </>
  )
}

export const PhotoLineWrapper = styled.div`
  overflow: hidden;
  position: relative;
  margin: 0 0 -${photoLineMargin}px 0;
  height: ${photoLineHeight}px;
`
