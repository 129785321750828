import { AsyncThunkAction } from 'actions/types'

export const TOGGLE_EMOJI_PICKER = 'TOGGLE_EMOJI_PICKER' as const

export interface OpenEmojiPickerAction {
  type: typeof TOGGLE_EMOJI_PICKER
  open: boolean
}

export const openEmojiPickerAction = (open: boolean): AsyncThunkAction => (
  dispatch,
  getState
) => {
  if (getState().emoji && getState().emoji.pickerOpened !== open) {
    return dispatch({
      type: TOGGLE_EMOJI_PICKER,
      open,
    })
  }
  return Promise.resolve()
}
