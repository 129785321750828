import styled from 'styled-components'

import { isLoveMailru } from 'common/constants'
import { OperationSystem } from 'common-constants/bowser'
import { Colors } from 'common-constants/colors'
import { FontFamilyBase, FontFamilyEmoji } from 'common-constants/fontFamily'
import { modalOpenAttribute } from 'components/layout/ModalLayout/modalOpen'
import { desktopSize, media } from 'components/presentational'

export const WrapperElement = styled.div<{
  $backgroundImageUrl?: string
  color?: string
  isAuthPage?: boolean
  isMambaPartner?: boolean
  $operationSystem?: OperationSystem
}>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    isLoveMailru(props.theme.partnerId) ? Colors.transparentFull : props.color};

  font-family: ${FontFamilyBase.base},
    ${({ $operationSystem }) => {
      switch ($operationSystem) {
        case OperationSystem.MacOs:
          return FontFamilyEmoji.macOS

        case OperationSystem.Linux:
          return FontFamilyEmoji.linux

        case OperationSystem.Windows:
        default:
          return FontFamilyEmoji.window
      }
    }};
  flex-grow: 1;
  vertical-align: middle;

  ${(props) =>
    props.$backgroundImageUrl &&
    `background-image: url(${props.$backgroundImageUrl});
    background-size: cover;
      background-repeat: no-repeat;
    transform: scaleX(${props.theme.scaleX});
    direction: ltr;`};

  ${(props) =>
    props.isAuthPage &&
    props.isMambaPartner &&
    `
  @media(min-width: 1024px) {
    overflow: hidden;
  }
`};

  ${(props) =>
    props.isAuthPage &&
    `@media (min-width: ${desktopSize}px) {
      align-items: center;
      justify-content: center;
    }`};

  ${media.phone`
    &[${modalOpenAttribute}=true] {
      display: none;
    }
  `};
`
WrapperElement.defaultProps = {
  color: Colors.white,
}
