import React, { FC, PropsWithChildren, ReactNode } from 'react'

import styled from 'styled-components'

import { mambaLayoutBottomMargin } from 'components/layout/MambaLayout/MambaLayout.constants'
import { Secondary } from 'components/layout/MambaLayout/Secondary'
import { contactListPath } from 'components/paths'
import { media } from 'components/presentational'
import { useHasUserRestrictions } from 'functions/hasUserRestrictions'
import { useLayoutContext } from 'hooks/useLayoutContext'

import { SideBar } from './SideBar'
import { ClientRender } from '../../../../client/ClientRender'

export const LayoutContent: FC<PropsWithChildren> = ({ children }) => {
  const userHasRestrictions = useHasUserRestrictions()
  const { baseUrl } = useLayoutContext()

  return (
    <Content
      $bottom={baseUrl === contactListPath ? 0 : mambaLayoutBottomMargin}
    >
      {!userHasRestrictions && (
        <Secondary>
          <ClientRender>
            <SideBar />
          </ClientRender>
        </Secondary>
      )}

      <Primary>{children}</Primary>
    </Content>
  )
}

export const Content = styled.div<{
  /**
   * Нужно, чтобы убрать отступ снизу, для мобильных контактов.
   */
  $bottom: number
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  flex-grow: 1;
  gap: 25px;

  ${media.phone<{ $bottom: number }>`    
    padding-bottom: ${(props) => props.$bottom}px;
  `};
`

export const Primary = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;
`
