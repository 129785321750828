import { HeaderHeight } from 'common-constants/header.height'
import { mailNavMarginBottom } from 'components/presentational/Navigation/Navigation.constants'

export const photoLineMargin = 225
export const photoLineHeight = 350
export const photoLineRealHeight = photoLineHeight - photoLineMargin

export const headerHeight = 64
export const headerMailRuHeight = 56
export const allHeadersMailRuHeight =
  HeaderHeight.menuTopMailRuHeight + headerMailRuHeight + mailNavMarginBottom

export const mambaLayoutBottomMargin = 20
