import loadable from '@loadable/component'

export const NavigationLoadable = loadable(() => import('./NavigationCommon'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('Navigation Common split error', error)
    }
    return undefined
  })({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
})
