import { useShallowEqualSelector } from './useShallowEqualSelector'

export const usePhotolineEnabled = () => {
  const { services } = useShallowEqualSelector(
    ({ systemReducer: { services } }) => ({
      services,
    })
  )

  return services?.photoline?.enabled
}
