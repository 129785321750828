import React from 'react'

import loadable from '@loadable/component'

import { ContactListShimmer } from 'components/presentational/shimmer/ContactListShimmer'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const ContactSideBarLoadable = loadable(
  () => import('./ContactsIndex'),
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('contacts sidebar split error', error)
      }
      return <ContactListShimmer />
    })(defaultLoadableProps),
  }
)
