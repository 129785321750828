import { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react'

import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

/**
 * Прослойка для того чтобы убрать ошибку для рендера только на клиенте, и только для авторизованных
 * https://react.dev/reference/react-dom/hydrate#handling-different-client-and-server-content
 * @returns
 */
export const ClientRender: FC<PropsWithChildren & { fallback?: ReactNode }> = ({
  children,
  fallback,
}) => {
  const [isClient, setIsClient] = useState(false)

  /**
   * Иначе на гео лендинге не видно левый блок.
   */
  const { javaScriptEnabled } = useShallowEqualSelector(
    ({ systemReducer: { javaScriptEnabled } }) => ({
      javaScriptEnabled,
    })
  )

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (isClient || !javaScriptEnabled) {
    return children
  }

  if (fallback) {
    return fallback
  }

  return null
}
