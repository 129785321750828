import React from 'react'

import { Replace } from 'components/system/Replace'
import { mergeAllUrls } from 'functions/mergeAllUrls'

import { withLayoutContext } from '../layout/MambaLayout/Context'

export const ModalRedirect = withLayoutContext(({ layoutContext, to, uid }) => (
  <Replace to={mergeAllUrls(layoutContext.baseUrl, to)} uid={uid} />
))
