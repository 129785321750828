import { css } from 'styled-components'

/**
 * Не стал использовать: background: ${Colors.backgroundLight};
 * Так как градиент.
 *
 * https://codepen.io/umng/pen/xMXXRj
 */
export const shimmerCss = css`
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;

  @keyframes shimmer {
    0% {
      background-position: -70vw 0;

      @media (min-width: 992px) {
        background-position: -220vw 0;
      }
    }

    100% {
      background-position: 70vw 0;

      @media (min-width: 992px) {
        background-position: -220vw 0;
      }
    }
  }
`
