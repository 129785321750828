import React, { FC, useEffect } from 'react'

import cookie from 'js-cookie'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import {
  fetchFakeAdsAction,
  setShowAdblockBannerAction,
} from 'actions/system/systemAction'
import { CloseSvg } from 'components/presentational/svg/CloseSvg'
import { isPwaSupported } from 'functions/pwa/isPwaSupported'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import banner from './ad-banner.png'
import { setCookieValue } from '../../../../client/functions/setCookie'

const bannerMainColor = '#f90'
const adblockBannerShownCookie = 'adblock_banner_shown'

const checkIsBannerHidden = () => {
  const isHidden = cookie.get(adblockBannerShownCookie)
  return isHidden === '1'
}

const Wrapper = styled.div`
  background: #ffffd7;
  border: 1px solid ${bannerMainColor};
  font-size: 20px;
  padding: 12px 10px 12px 30px;
  margin: 0 0 20px 0;
  z-index: 1;
  display: flex;
`

const Message = styled.div`
  text-align: center;
  flex-grow: 1;
`

const Close = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 30px;
`

export const AdBlockBanner: FC = () => {
  const dispatch = useDispatch()

  const { showAdblockBanner } = useShallowEqualSelector(
    ({ systemReducer: { showAdblockBanner } }) => ({
      showAdblockBanner,
    })
  )

  const handleClose = () => {
    try {
      setCookieValue(adblockBannerShownCookie, '1', 7)
    } catch (error) {
      console.error('Adblock cookie close failed', error)
    }

    dispatch(setShowAdblockBannerAction(false))
  }

  useEffect(() => {
    if (checkIsBannerHidden()) {
      return
    }

    /** Не понятно как отличить блокировку от проблемы с сетью */
    if (!isPwaSupported()) {
      dispatch(fetchFakeAdsAction(banner))
    }
  }, [])

  if (!showAdblockBanner) {
    return null
  }

  /**
   * Ссылки как отключить АдБлок, уже не существует.
   */
  return (
    <Wrapper>
      <Message>
        <FormattedMessage
          id={'adblock.message'}
          defaultMessage="Для корректного отображения контента рекомендуем добавить сайт в исключения вашего блокировщика баннеров."
        />
      </Message>
      <Close onClick={handleClose}>
        <CloseSvg stroke={bannerMainColor} width="16" height="16" />
      </Close>
    </Wrapper>
  )
}
