import { Reducer } from 'redux'

import {
  BannerActionTypes,
  BANNERS_AVAILABLE,
} from 'actions/banner/bannerAction'
import {
  CLOSE_INSTALL_APP_BANNER,
  closeInstallAppBannerAction,
} from 'actions/banner/closeInstallAppBannerAction'
import {
  CLOSE_INSTALL_PWA_BANNER,
  closeInstallPwaBannerAction,
} from 'actions/banner/closeInstallPwaBannerAction'
import {
  LocalStorageActionTypes,
  RESTORE_PARTIAL_STATE,
} from 'actions/system/localStorageAction'
import { RESTORE_INITIAL_STATE } from 'actions/system/restoreInitialStateAction'
import { BannerReducerState } from 'reducers/banner/BannerReducerState'
import { initialStateReducer } from 'reducers/default/initialStateReducer'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'

const initialState: BannerReducerState = {
  enabled: false,
  maxAds: false,
  directAdFox: [],
  loading: false,
  loaded: false,
  installAppBannerClosedDate: 0,
  installPwaBannerClosedDate: 0,
  installPwaBannerClosedTimes: 0,
}

export const bannerReducer: Reducer<
  BannerReducerState,
  | BannerActionTypes
  | ReturnType<typeof closeInstallAppBannerAction>
  | ReturnType<typeof closeInstallPwaBannerAction>
  | LocalStorageActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case RESTORE_INITIAL_STATE:
      return initialStateReducer(state, action, 'bannerReducer')

    case RESTORE_PARTIAL_STATE:
      return { ...state, ...action.partialState.bannerReducer }

    case BANNERS_AVAILABLE:
      return defaultPromiseReducer(
        state,
        action,
        () => ({ loading: true }),
        (result) => {
          return {
            enabled: result.available,
            maxAds: result.maxAds,
            directAdFox: result.directAdFox || [],
            loading: false,
            loaded: true,
          }
        },
        () => ({ loading: false })
      )

    case CLOSE_INSTALL_APP_BANNER:
      return { ...state, installAppBannerClosedDate: Date.now() }

    case CLOSE_INSTALL_PWA_BANNER:
      return {
        ...state,
        installPwaBannerClosedDate: Date.now(),
        installPwaBannerClosedTimes: state.installPwaBannerClosedTimes + 1,
      }

    default:
      return state
  }
}
