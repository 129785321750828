import { AsyncThunkActionVoid } from 'actions/actions.types'

export const TOGGLE_OPEN_CONTACTS = 'TOGGLE_OPEN_CONTACTS' as const

export const toggleOpenContactsAction = (
  contactsOpened: boolean
): AsyncThunkActionVoid => (dispatch, getState) => {
  const { chatMessengerReducer } = getState()

  if (
    chatMessengerReducer &&
    contactsOpened !== chatMessengerReducer.contactsOpened
  ) {
    dispatch(toggleOpenContactsPlainAction(contactsOpened))
  }
}

export const toggleOpenContactsPlainAction = (contactsOpened: boolean) => ({
  type: TOGGLE_OPEN_CONTACTS,
  contactsOpened,
})
