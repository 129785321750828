import { isLoveMailru } from 'common/constants'
import { HeaderHeight } from 'common-constants/header.height'
import { mailNavMarginBottom } from 'components/presentational/Navigation/Navigation.constants'

export const resolveHeaderHeight = (partnerId: number): number => {
  if (partnerId) {
    return isLoveMailru(partnerId)
      ? HeaderHeight.allHeadersMailRuHeight + mailNavMarginBottom
      : HeaderHeight.common
  }

  return HeaderHeight.common
}
